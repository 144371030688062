import React from 'react'
import { node } from 'prop-types'

const FooterContainer = ({ children }) => <footer>{children}</footer>

FooterContainer.propTypes = {
  children: node
}

export default FooterContainer
