import React from 'react'
import classNames from 'classnames'
import { bool, node, oneOf, string } from 'prop-types'
// import sassKeysToArray from '../../../lib/sass-keys-to-array'

import styles from './Inline.module.scss'

// const gaps = sassKeysToArray(styles.gaps)
const gaps = ['s', 'm', 'l', 'xl']

/**
 * A wrapper component which evenly horizontally spaces its children.
 */

const Inline = ({ as, children, gap, shrink, justify, align }) => {
  const InlineEl = as || 'div'

  // Wrapping div avoids issue with negative margin clashing with margins applied by wrapping elements
  return (
    <div>
      <InlineEl
        className={classNames(
          styles.Inline,
          gap && styles[gap],
          shrink && styles.shrink,
          justify && styles[`justify-${justify}`],
          align && styles[`align-${align}`]
        )}
      >
        {children}
      </InlineEl>
    </div>
  )
}

Inline.defaultProps = {
  gap: 'm'
}

Inline.propTypes = {
  as: string,
  children: node.isRequired,
  gap: oneOf(gaps),
  justify: oneOf(['start', 'center', 'end', 'spaceAround', 'spaceBetween']),
  align: oneOf(['start', 'center', 'end']),
  /** Wrapper shrinks to fit content if narrower than container */
  shrink: bool
}

export default Inline
