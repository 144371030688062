import React, { useContext, useState, useEffect } from 'react'
import { array } from 'prop-types'

import Head from 'next/head'
import { updateProfile } from 'src/lib/auth-api'
import { AuthContext } from 'src/component/Context/AuthContext'
import ProfilePage from 'src/component/Pages/Profile'
// import { getReviews } from 'src/lib/reviews-api'
import DashboardPage from 'src/component/Composite/DashboardPage'

export default function Profile({ reviews }) {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(undefined)
  const [errorMessage, setErrorMessage] = useState(false)
  const [validation, setValidation] = useState({})
  const {
    auth: { loginForms, user },
    dispatch
  } = useContext(AuthContext)

  const updateForm = (e) => {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value

    dispatch({ type: 'SET_FORM', key: e.target.name, value })
  }

  useEffect(() => {
    if (user) {
      dispatch({
        type: 'RESET_FORM',
        form: {
          emailAddress: user.emailAddress,
          firstName: user.firstName,
          lastName: user.lastName,
          emailNewsletter: user.permissions.marketing.email
        }
      })
    }
  }, [user]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async (e) => {
    e.preventDefault()

    setLoading(true)

    try {
      const user = await updateProfile(loginForms)

      dispatch({ type: 'SET_USER', user })
      setSuccess(true)
    } catch (e) {
      setSuccess(false)
      if (e.validation) {
        setErrorMessage('Please check the form for errors and try again.')
        setValidation(e.errors)
      } else {
        setErrorMessage(e.message)
        setValidation({})
      }
    }

    setLoading(false)
  }

  return (
    <>
      <Head>
        <title>Home | Citygate Portal</title>
      </Head>
      <DashboardPage reviews={reviews}>
        <ProfilePage
          onSubmit={handleSubmit}
          error={errorMessage}
          success={success === true}
          validation={validation}
          loading={loading}
          formData={loginForms}
          onChange={updateForm}
        />
      </DashboardPage>
    </>
  )
}

Profile.propTypes = {
  reviews: array
}

export async function getStaticProps() {
  const reviews = [] // await getReviews()

  return {
    props: { reviews },
    // Next.js will attempt to re-generate the page:
    // - When a request comes in
    // - At most once every hour
    revalidate: 60 * 60
  }
}
