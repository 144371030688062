import React from 'react'
import { node, number, string } from 'prop-types'

import styles from './ReviewCard.module.scss'

import Rating from '@/component/Primitive/Rating'
import Stack from '@/component/Primitive/Stack'
import Type from '@/component/Primitive/Type'

/**
 * Starter component which can be duplicated and used as a starting point for
 * others.
 */

const ReviewCard = ({ attribution, children, rating }) => (
  <article className={styles.ReviewCard}>
    <Type as="blockquote">
      <Stack gap="l">
        <div className={styles.ReviewCardRating}>
          <Rating rating={rating} total={5} />
        </div>
        <p>{children}</p>
        {attribution && (
          <Type as="cite" size="xs" bold color="coolgrey-l50">
            {attribution}
          </Type>
        )}
      </Stack>
    </Type>
  </article>
)

ReviewCard.propTypes = {
  attribution: string,
  children: node.isRequired,
  rating: number
}

export default ReviewCard
