import React from 'react'
import { bool, func, object, string } from 'prop-types'

import ButtonStandard from '@/component/Primitive/ButtonStandard'
import CheckControl from '@/component/Primitive/CheckControl'
import Container from '@/component/Primitive/Container'
import FieldTemplate from '@/component/Primitive/FieldTemplate'
import Grid from '@/component/Primitive/Grid'
import Notification from '@/component/Primitive/Notification'
import Rule from '@/component/Primitive/Rule'
import Stack from '@/component/Primitive/Stack'
import TextAlign from '@/component/Primitive/TextAlign'
import TextControl from '@/component/Primitive/TextControl'
import PageSummary from '@/component/Primitive/PageSummary'
import Section from '@/component/Primitive/Section'

const ProfilePage = ({
  formData,
  onChange,
  onSubmit,
  loading,
  error,
  success,
  validation
}) => (
  <form onSubmit={onSubmit}>
    <Section minHeight padded>
      <Container gutter center size="m">
        <Stack gap="l">
          <PageSummary
            title="Your Details"
            description="Edit your basic details"
          />

          <Stack gap="m">
            <Grid gutter="default">
              <Grid.Item width={[1, 1 / 2]}>
                <FieldTemplate
                  label="First Name"
                  required
                  controlName="firstName"
                  feedback={validation && validation.firstName}
                  status={validation && validation.firstName && 'error'}
                  autoComplete="given-name"
                >
                  <TextControl
                    name="firstName"
                    required
                    onChange={onChange}
                    value={formData.firstName}
                  />
                </FieldTemplate>
              </Grid.Item>
              <Grid.Item width={[1, 1 / 2]}>
                <FieldTemplate
                  label="Last Name"
                  required
                  controlName="lastName"
                  feedback={validation && validation.lastName}
                  status={validation && validation.lastName && 'error'}
                  autoComplete="family-name"
                >
                  <TextControl
                    name="lastName"
                    required
                    onChange={onChange}
                    value={formData.lastName}
                  />
                </FieldTemplate>
              </Grid.Item>
            </Grid>

            <FieldTemplate
              label="Email Address"
              required
              controlName="emailAddress"
              feedback={validation && validation.emailAddress}
              status={validation && validation.emailAddress && 'error'}
            >
              <TextControl
                type="email"
                name="emailAddress"
                required
                onChange={onChange}
                value={formData.emailAddress}
                autoComplete="email"
              />
            </FieldTemplate>

            <FieldTemplate
              template="check"
              label="I'd like Citygate to send me news and offers via the following:"
              controlName="emailNewsletter"
            >
              <CheckControl
                type="checkbox"
                name="emailNewsletter"
                onChange={onChange}
                checked={formData.emailNewsletter}
              >
                Email
              </CheckControl>
            </FieldTemplate>
            <Rule muted />
          </Stack>

          {error && <Notification status="error">{error}</Notification>}
          {success && (
            <Notification status="info">Profile Updated</Notification>
          )}

          <TextAlign right>
            <ButtonStandard type="submit" loading={loading}>
              Save Profile
            </ButtonStandard>
          </TextAlign>
        </Stack>
      </Container>
    </Section>
  </form>
)

ProfilePage.propTypes = {
  formData: object,
  onChange: func,
  onSubmit: func,
  error: string,
  success: bool,
  validation: object,
  loading: bool
}

export default ProfilePage
