import React from 'react'
// import { node } from 'prop-types'

import styles from './HighlightsBanner.module.scss'
import Icon from '@/component/Primitive/Icon'

const items = [
  {
    label: 'Satisfaction Guaranteed',
    icon: 'highlight-membership-card',
    href: ''
  },
  {
    label: 'Price Guaranteed',
    icon: 'highlight-bill-tick',
    href: ''
  },
  {
    label: 'New & Used Vehicles',
    icon: 'highlight-stars',
    href: ''
  },
  {
    label: 'Car & Van Rentals',
    icon: 'highlight-express',
    href: ''
  },
  {
    label: 'Aftersales & Servicing',
    icon: 'highlight-task-tick',
    href: ''
  },
  {
    label: 'Fleet & Business',
    icon: 'highlight-handshake',
    href: ''
  }
  // {
  //   label: 'Paint & Bodyshop',
  //   icon: 'drop',
  //   href: ''
  // }
]

/**
 */

const HighlightsBanner = () => (
  <div className={styles.HighlightsBanner}>
    <div className={styles.HighlightsBannerHeader}>
      <div className={styles.HighlightsBannerHeaderInner}>
        <Icon height={42} type="logo" a11yText="Citygate" />
        <div>Committed to Excellence</div>
      </div>
    </div>
    <ul className={styles.HighlightsBannerList}>
      {items.map((item) => {
        const ItemEl = item.href ? 'a' : 'div'
        return (
          <li
            key={`HighlightsBanner:${item.label}`}
            className={styles.HighlightsBannerListItem}
          >
            <ItemEl {...(item.href && { href: item.href })}>
              <div className={styles.HighlightsBannerIcon}>
                <Icon type={item.icon} a11yText="" />
              </div>
              <div>{item.label}</div>
            </ItemEl>
          </li>
        )
      })}
    </ul>
  </div>
)

// HighlightsBanner.propTypes = {}

export default HighlightsBanner
