import React from 'react'
import { bool, node, string } from 'prop-types'
import classNames from 'classnames'

import styles from './Section.module.scss'

/**
 * A wrapping component used to separate distinct areas of content. Has some
 * variations for backgrounds, borders and shadows.
 */

const Section = ({
  as,
  borderBottom,
  backgroundLight,
  children,
  id,
  minHeight,
  padded,
  shadowBottom,
  shadowTop
}) => {
  const SectionEl = as || 'div'
  return (
    <SectionEl
      className={classNames(
        styles.Section,
        backgroundLight && styles.backgroundLight,
        borderBottom && styles.borderBottom,
        minHeight && styles.minHeight,
        padded && styles.padded,
        shadowBottom && styles.shadowBottom,
        shadowTop && styles.shadowTop
      )}
      id={id}
    >
      {children}
    </SectionEl>
  )
}

Section.propTypes = {
  as: string,
  borderBottom: bool,
  backgroundLight: bool,
  children: node.isRequired,
  id: string,
  minHeight: bool,
  padded: bool,
  shadowBottom: bool,
  shadowTop: bool
}

export default Section
