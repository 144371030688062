import React, { useState } from 'react'
import { arrayOf, bool, func, shape, string } from 'prop-types'
import classNames from 'classnames'

import stringToInitials from '@/lib/string-to-initials'

import styles from './HeaderBar.module.scss'

import Avatar from '@/component/Primitive/Avatar'
import ButtonStandard from '@/component/Primitive/ButtonStandard'
import Icon from '@/component/Primitive/Icon'
import LogoLockup from '@/component/Primitive/LogoLockup'
import SmartLink from '@/component/Primitive/SmartLink'

const HeaderBar = ({ items, userItems, user, loginPath }) => {
  const [mobileNavOpen, toggleMobileNav] = useState(false)
  const [userNavOpen, toggleUserNav] = useState(false)

  const handleToggleMobileNav = () => {
    toggleMobileNav(!mobileNavOpen)
    toggleUserNav(false)
  }

  const handleToggleUserNav = () => {
    toggleUserNav(!userNavOpen)
    toggleMobileNav(false)
  }

  return (
    <header className={styles.HeaderBar}>
      <div className={styles.HeaderBarLogo}>
        <LogoLockup
          as="h1"
          logo="mycitygate"
          title="Customer Portal"
          tabletMinimal
          mobileSmall
        />
      </div>

      <nav
        className={classNames(
          styles.HeaderBarNav,
          mobileNavOpen && styles.open
        )}
        aria-label="Primary"
      >
        {items.length > 0 && (
          <ul className={styles.HeaderBarNavList}>
            {items.map(({ href, to, onClick, active, icon, text }) => (
              <li
                key={`HeaderNavLink:${text}`}
                className={styles.HeaderBarNavItem}
              >
                <SmartLink
                  className={classNames(
                    styles.HeaderBarNavLink,
                    active && styles.active
                  )}
                  href={href}
                  to={to}
                  onClick={onClick}
                >
                  {icon && (
                    <>
                      <Icon type={icon} a11yText="" />{' '}
                    </>
                  )}
                  {text}
                </SmartLink>
              </li>
            ))}
          </ul>
        )}
      </nav>

      {user && userItems.length > 0 && (
        <nav
          className={classNames(
            styles.HeaderBarUserNav,
            userNavOpen && styles.open
          )}
          aria-label="Secondary"
        >
          <ul className={styles.HeaderBarUserNavList}>
            {userItems.map(({ href, to, onClick, active, text }) => (
              <li
                key={`HeaderUserNavLink:${text}`}
                className={styles.HeaderBarUserNavItem}
              >
                <SmartLink
                  className={classNames(
                    styles.HeaderBarNavLink,
                    styles.HeaderBarUserNavLink,
                    active && styles.active
                  )}
                  href={href}
                  to={to}
                  onClick={onClick}
                >
                  {text}
                </SmartLink>
              </li>
            ))}
          </ul>
        </nav>
      )}

      <button
        type="button"
        onClick={handleToggleMobileNav}
        className={classNames(
          styles.HeaderBarNavLink,
          styles.HeaderBarMobileToggle
        )}
      >
        <Icon
          type={mobileNavOpen ? 'close' : 'menu'}
          a11yText="Toggle primary navigation"
        />
      </button>

      {user && (
        <button
          type="button"
          onClick={handleToggleUserNav}
          className={classNames(
            styles.HeaderBarNavLink,
            styles.HeaderBarUserNavToggle
          )}
        >
          <div className={styles.HeaderBarUserNavAvatar}>
            <Avatar
              size={32}
              initials={stringToInitials(`${user.firstName} ${user.lastName}`)}
            />
          </div>
          {user.firstName && user.lastName && (
            <span className={styles.HeaderBarUserNavName}>
              {user.firstName.slice(0, 1)}. {user.lastName}
            </span>
          )}
          <div className={styles.HeaderBarUserNavCarat}>
            <Icon
              type={userNavOpen ? 'expand-less' : 'expand-more'}
              a11yText="Toggle secondary navigation"
            />
          </div>
        </button>
      )}

      {!user && loginPath && (
        <div className={styles.HeaderBarLogin}>
          <ButtonStandard inverse outline size="xs" to={loginPath}>
            Login
          </ButtonStandard>
        </div>
      )}
    </header>
  )
}

HeaderBar.defaultProps = {
  items: [],
  userItems: []
}

HeaderBar.propTypes = {
  items: arrayOf(
    shape({
      text: string.isRequired,
      to: string,
      href: string,
      onClick: func,
      icon: string,
      active: bool
    })
  ),
  userItems: arrayOf(
    shape({
      text: string.isRequired,
      to: string,
      href: string,
      onClick: func,
      active: bool
    })
  ),
  user: shape({
    firstName: string.isRequired,
    lastName: string.isRequired
  }),
  loginPath: string
}

export default HeaderBar
