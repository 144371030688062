import React from 'react'
import { bool } from 'prop-types'
import classNames from 'classnames'

import styles from './Rule.module.scss'

/**
 * A simple horizontal rule, with zero height to not affect vertical
 * rhythm. Can optionally render as a \`<hr />\` if it makes semantic sense.
 */

const Rule = ({ functional, muted }) => {
  const RuleEl = functional ? 'hr' : 'div'
  return <RuleEl className={classNames(styles.Rule, muted && styles.muted)} />
}

Rule.propTypes = {
  functional: bool,
  muted: bool
}

export default Rule
