import React from 'react'
import { node } from 'prop-types'

import styles from './SimplePage.module.scss'

const SimplePage = ({ children, headerContent, footerContent }) => (
  <div className={styles.SimplePage}>
    {headerContent && (
      <div className={styles.SimplePageHeader}>{headerContent}</div>
    )}
    <div className={styles.SimplePageMain}>{children}</div>
    {footerContent && (
      <div className={styles.SimplePageContentFooter}>{footerContent}</div>
    )}
  </div>
)

SimplePage.propTypes = {
  children: node.isRequired,
  headerContent: node,
  footerContent: node
}

export default SimplePage
