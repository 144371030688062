import React from 'react'
import { number, string } from 'prop-types'

import fontSizeFormatter from './lib/font-size-formatter'

import styles from './Avatar.module.scss'

const Avatar = ({ initials, name, size }) => (
  <div
    className={styles.Avatar}
    style={{
      width: size,
      height: size,
      lineHeight: `${size}px`,
      ...(initials && { fontSize: fontSizeFormatter({ initials, size }) })
    }}
    title={name || null}
    aria-hidden={!name || null}
  >
    {initials && (
      <div aria-label="Initials" className={styles.AvatarInitials}>
        {initials}
      </div>
    )}
  </div>
)

Avatar.defaultProps = {
  size: 48
}

Avatar.propTypes = {
  initials: string,
  name: string,
  size: number
}

export default Avatar
