import React from 'react'
import { node, array } from 'prop-types'

import SimplePage from '@/component/Landmark/SimplePage'
import Header from 'src/component/Composite/Header'

import Stack from '@/component/Primitive/Stack'
import FooterContainer from '@/component/Landmark/FooterContainer'
import HighlightsBanner from '@/component/Primitive/HighlightsBanner'
import FooterContact from '@/component/Landmark/FooterContact'
import FooterLegal from '@/component/Landmark/FooterLegal'
import ReviewCard from '@/component/Primitive/ReviewCard'
import ReviewCards from '@/component/Primitive/ReviewCards'

export default function DashboardPage({ children, reviews }) {
  const hasReviews = reviews && reviews.length > 0

  const formatted = reviews
    .filter((review) => review.content && review.content.length < 280)
    .map((review, i) => (
      <ReviewCard
        key={`ReviewCard:${i}`}
        attribution={review.name}
        rating={review.rating}
      >
        {review.content}
      </ReviewCard>
    ))

  return (
    <SimplePage
      headerContent={<Header />}
      footerContent={
        <Stack gap="l">
          <FooterContainer>
            <HighlightsBanner />
            <FooterContact />
            <FooterLegal />
          </FooterContainer>
        </Stack>
      }
    >
      {children}
      {hasReviews && <ReviewCards title="Reviews" slides={formatted} />}
    </SimplePage>
  )
}

DashboardPage.propTypes = {
  children: node,
  reviews: array
}
