import React from 'react'
import { node, number, shape, string } from 'prop-types'

import styles from './PageSummary.module.scss'

import Type from '@/component/Primitive/Type'
import ResponsiveMedia from '@/component/Primitive/ResponsiveMedia'

const PageSummary = ({ title, description, image, action }) => (
  <div className={styles.PageSummary}>
    <div className={styles.PageSummaryContent}>
      <Type size="xl" color="blue" bold>
        {title}
      </Type>

      {description && <Type size="m">{description}</Type>}
    </div>
    {!action && image && (
      <div className={styles.PageSummaryImage}>
        <ResponsiveMedia ratio={image.height / image.width}>
          <img alt="" {...image} />
        </ResponsiveMedia>
      </div>
    )}
    {action && <div className={styles.PageSummaryAction}>{action}</div>}
  </div>
)

PageSummary.propTypes = {
  action: node,
  title: string.isRequired,
  description: string,
  image: shape({
    src: string,
    width: number,
    height: number,
    alt: string
  })
}

export default PageSummary
