import React, { useState } from 'react'
import { arrayOf, func, number, object, string } from 'prop-types'

import { Swiper, SwiperSlide } from 'swiper/react'
// import SwiperCore, { Pagination } from 'swiper/core'

import styles from './ReviewCards.module.scss'

import Container from '@/component/Primitive/Container'
import Icon from '@/component/Primitive/Icon'
import Inline from '@/component/Primitive/Inline'
import PaginationButton from '@/component/Primitive/PaginationButton'
import SmartLink from '@/component/Primitive/SmartLink'
import Spacer from '@/component/Primitive/Spacer'
import Stack from '@/component/Primitive/Stack'
import Type from '@/component/Primitive/Type'

import contact from '@/data/contact'

/**
 */

const ReviewCards = ({ title, slides, initialSlide, onChange }) => {
  const [activeSlide, setActiveSlide] = useState(initialSlide || 0)
  const [activeSwiper, setActiveSwiper] = useState(null)

  // SwiperCore.use([Pagination])

  const handlePrev = () => {
    activeSwiper.slidePrev()
  }

  const handleNext = () => {
    activeSwiper.slideNext()
  }

  const handleSlideChange = (a) => {
    setActiveSlide(a.realIndex)
    onChange && onChange(a.realIndex)
  }

  return (
    <div className={styles.ReviewCards}>
      <Stack gap="l">
        <Container size="xxl" gutter center>
          <div className={styles.ReviewCardsHeader}>
            <Type as="h2" size="xl" bold>
              {title}
            </Type>
            {slides.length > 0 && (
              <div className={styles.ReviewCardsNav}>
                <PaginationButton
                  direction="previous"
                  onClick={handlePrev}
                  disabled={activeSlide === 0}
                />

                <PaginationButton
                  direction="next"
                  onClick={handleNext}
                  disabled={activeSlide === slides.length - 1}
                />
              </div>
            )}
          </div>
        </Container>

        <div className={styles.ReviewCardsCarousel}>
          <Swiper
            onSwiper={setActiveSwiper}
            onSlideChange={handleSlideChange}
            centeredSlides
            spaceBetween={8}
            slidesPerView={1.2}
            breakpoints={{
              0: {
                slidesPerView: 1.2,
                spaceBetween: 8
              },
              480: {
                slidesPerView: 2.2,
                spaceBetween: 16
              },
              768: {
                slidesPerView: 3.2,
                spaceBetween: 24,
                initialSlide: 1
              },
              1200: {
                slidesPerView: 4.2,
                spaceBetween: 32,
                initialSlide: 1
              },
              1600: {
                slidesPerView: 5.2,
                spaceBetween: 40,
                initialSlide: 2
              }
            }}
          >
            {slides.map((slide, i) => (
              <SwiperSlide key={`Swiper:${i}`}>{slide}</SwiperSlide>
            ))}
          </Swiper>
        </div>

        <div className={styles.ReviewCardsFooter}>
          <Inline gap="s" justify="center">
            <SmartLink
              href={contact.reviews.google.href}
              className={styles.ReviewCardsFooterLink}
            >
              <Icon
                type="logo-google"
                a11yText={contact.reviews.google.cta}
                height={40}
              />
            </SmartLink>
            <SmartLink
              href={contact.reviews.trustpilot.href}
              className={styles.ReviewCardsFooterLink}
            >
              <Spacer style={{ transform: 'translateY(-5px)' }}>
                <Icon
                  type="logo-trustpilot"
                  a11yText={contact.reviews.trustpilot.cta}
                  height={44}
                />
              </Spacer>
            </SmartLink>
          </Inline>
        </div>
      </Stack>
    </div>
  )
}

ReviewCards.defaultProps = {
  slides: []
}

ReviewCards.propTypes = {
  slides: arrayOf(object),
  initialSlide: number,
  onChange: func,
  title: string
}

export default ReviewCards
