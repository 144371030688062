import React from 'react'
// import {} from 'prop-types'

import styles from './FooterLegal.module.scss'

import Icon from '@/component/Primitive/Icon'
import Type from '@/component/Primitive/Type'
import Container from '@/component/Primitive/Container'

const FooterLegal = () => (
  <div className={styles.FooterLegal}>
    <Container gutter center size="full">
      <div className={styles.FooterLegalInner}>
        <small className={styles.FooterLegalSmall}>
          <Type size="xs" color="coolgrey-l50">
            Citygate Automotive Ltd (FRN : 446959) are authorised and regulated
            by the Financial Conduct Authority for the purpose of Insurance
            Mediation & Credit Broking. Reg.&nbsp;Office: 664 Victoria Road,
            Ruislip, Middlesex HA4&nbsp;0LN :: Reg.&nbsp;Company&nbsp;No.
            05615547 VAT&nbsp;No. 872&nbsp;286&nbsp;890
          </Type>
        </small>
        <div>
          <Type size="xs" color="coolgrey-l50">
            Powered by <a href="https://auto.works/">auto.works</a>{' '}
            <Icon type="autoworks" a11yText="" width={20} />
          </Type>
        </div>
      </div>
    </Container>
  </div>
)

FooterLegal.propTypes = {}

export default FooterLegal
