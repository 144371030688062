import React from 'react'
import { arrayOf, string } from 'prop-types'
import classNames from 'classnames'

import styles from './FooterContact.module.scss'

import Container from '@/component/Primitive/Container'
import Icon from '@/component/Primitive/Icon'
import List from '@/component/Primitive/List'
import Type from '@/component/Primitive/Type'

import contact from '@/data/contact'

const links = [
  {
    label: 'Sales:',
    icon: 'phone',
    ...contact.phone.sales
  },
  {
    label: 'Service:',
    icon: 'phone',
    ...contact.phone.service
  },
  {
    icon: 'phone',
    display: 'Accident Helpline!',
    href: 'https://citygate.co.uk/#accident',
    highlight: true,
    newWindow: true
  }
]

const FooterContact = ({ socialIds }) => (
  <div className={styles.FooterContact}>
    <Container gutter center size="full">
      <div className={styles.FooterContactInner}>
        <div className={styles.FooterContactLinks}>
          <List unstyled>
            {links.map((link, i) => (
              <li
                key={`FooterContactLink:${i}`}
                className={styles.FooterContactLinkListItem}
              >
                <a
                  href={link.href}
                  className={classNames(
                    styles.FooterContactLink,
                    link.highlight && styles.highlight
                  )}
                  {...(link.newWindow && {
                    target: '_blank',
                    rel: 'noopener'
                  })}
                >
                  {link.icon && (
                    <div className={styles.FooterContactLinkIcon}>
                      <Icon type={link.icon} a11yText="" />{' '}
                    </div>
                  )}
                  <Type size="xs" color="white">
                    {link.label && (
                      <Type as="span" size="xs" bold color="white">
                        {link.label}{' '}
                      </Type>
                    )}
                    <div className={styles.FooterContactLinkDisplay}>
                      {link.display}
                    </div>
                  </Type>
                </a>
              </li>
            ))}
          </List>
        </div>
        <div className={styles.FooterContactSocial}>
          <List unstyled inline>
            {socialIds.map((platformId) => {
              const platform = contact.social.global[platformId]
              if (!platform) return null
              return (
                <li key={`FooterSocialPlatform:${platformId}`}>
                  <a
                    href={platform.href}
                    className={styles.FooterContactSocialLink}
                  >
                    <Icon
                      type={`social-${platformId}`}
                      a11yText={platform.cta}
                      width={16}
                    />
                  </a>
                </li>
              )
            })}
          </List>
        </div>
      </div>
    </Container>
  </div>
)

FooterContact.defaultProps = {
  socialIds: ['twitter', 'facebook', 'linkedin', 'instagram', 'youtube']
}

FooterContact.propTypes = {
  socialIds: arrayOf(string)
}

export default FooterContact
